export const FEES = {
  JV: 30,
  V_FIRST: 30,
  V_ADD: 5,
  DEBATE: 35,
  JR: 25,
};

export const EVENT_CONST = {
  VARSITY: 0,
  DEBATE: 1,
  JV: 2,
  JR: 3,
};
