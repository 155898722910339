import * as React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Icon from "@material-ui/core/Icon";

export default function Header() {
  const useStyles = makeStyles({
    imageIcon: {
      display: "flex",
      height: "inherit",
      width: "inherit",
    },
    iconRoot: {
      textAlign: "center",
    },
  });
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <Icon
          style={{ marginRight: "10px" }}
          classes={{ root: classes.iconRoot }}
        >
          <img
            className={classes.imageIcon}
            src="favicon.ico"
            style={{ maxWidth: "100%" }}
          />
        </Icon>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          CCA Tournament Tool
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

/*<Button color="inherit" href="/">
Registration Tools
</Button>
<Button color="inherit" href="/labels">
  Ballot Labels
</*/
