import parseFile from "./parseTournFile";
import generateCsv from "./generateCsv";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./table.css";
import entryCsv from "./generateCsvEntries";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import GenLabels from "./genLabels";
import { InfoLabel } from "@fluentui/react-components";

export default function App() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [clubData, setClubData] = useState<any>([]);
  const [events, setEvents] = useState<any>([]);
  const [labelData, setLabelData] = useState<any>([]);
  const [seperate, setSeperate] = useState<boolean>(false);
  const [debatePool, setDebatePool] = useState<string>("Debate Judges");
  const [iePool, setIEPool] = useState<string>("IE Judges");
  const [judges, setJudges] = useState<any>(null);
  const changeHandler = (newFile: any) => {
    setSelectedFile(newFile);
    handleSubmission(newFile, seperate);
  };
  const [label, setLabel] = useState<boolean>(false);
  const [reg, setReg] = useState<boolean>(false);
  const [labelEvent, setLabelEvent] = useState<any>("");
  const [eventIndex, setEventIndex] = useState<number | null>(null);
  const [labelRound, setLabelRound] = useState<any>("");
  const [allEntries, setAllEntries] = useState<any>(null);
  const [rooms, setRooms] = useState<any>(null);
  const [startingLabel, setStartingLabel] = useState<string>("1");
  const [numJudges, setNumJudges] = useState<string>("1");
  const [final, setFinal] = useState<boolean>(false);
  const [flip, setFlip] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedFile(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function genCsv() {
    generateCsv(clubData);
  }
  async function genEntries() {
    entryCsv(clubData, events);
  }
  async function handleSubmission(newFile: File | null, isSeperate: boolean) {
    if (!newFile) {
      return;
    }
    const data = await parseFile(
      newFile,
      isSeperate,
      debatePool,
      iePool,
      handleClickOpen
    );
    if (!data) {
      return;
    }

    setClubData(data.clubData);
    setLabelData(data.labelData);
    setEvents(data.events);
    setJudges(data.judges);
    setAllEntries(data.allEntries);
    setRooms(data.rooms);
    setLabelRound("");
    setLabelEvent("");
    setStartingLabel("1");
    setEventIndex(null);
    setNumJudges("1");
  }
  async function handleChange(event: number) {
    if (event === 2) {
      setLabel(true);
      setReg(false);
    } else if (event === 1) {
      setLabel(false);
      setReg(true);
    }
  }
  async function handleNewEvent(event: any) {
    setLabelEvent(event);
    const findEventIndex = labelData.findIndex(
      (labelEvent: any) => labelEvent.id === event
    );
    setEventIndex(findEventIndex);
    setLabelRound("");
    setNumJudges("1");
    setStartingLabel("1");
  }
  async function handleNewRound(round: any) {
    if (eventIndex !== null) {
      const toSend = labelData[eventIndex].rounds.find(
        (lr: any) => lr.id === round
      );
      if (toSend.type === "final") {
        setFinal(true);
      } else {
        setFinal(false);
        setFlip(false);
      }
      const j = toSend?.settings?.find((s: any) => s.tag === "num_judges");
      if (j && j.value) {
        setNumJudges(j.value);
      }
    }
    setLabelRound(round);
    setNumJudges("1");
    setStartingLabel("1");
  }

  async function ballotLabels() {
    if (eventIndex != null && eventIndex >= 0) {
      const toSend = labelData[eventIndex].rounds.find(
        (lr: any) => lr.id === labelRound
      );
      const start = parseInt(startingLabel) ?? 1;
      GenLabels(
        toSend,
        judges,
        allEntries,
        rooms,
        labelData[eventIndex].abbr,
        start,
        parseInt(numJudges) ?? 1,
        clubData,
        flip
      );
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Trouble in paradise"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Invalid File. Please check the file and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ padding: "20px", margin: "auto", width: "450px" }}>
        <h2 style={{ textAlign: "center" }}>
          Tournament File Upload{" "}
          <InfoLabel
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              verticalAlign: "middle",
              display: "inline-block",
            }}
            info={
              <>
                <p> {"Export file from Tabroom Tournament -> Entries ->"}</p>
                <p> {"Data -> Export Tabroom Data -> Entire Tournament"}</p>
              </>
            }
          />
        </h2>

        <div style={{ margin: "auto", width: "450px" }}>
          <MuiFileInput
            placeholder="Select Tournament File"
            value={selectedFile}
            onChange={changeHandler}
            style={{ width: "450px", display: "inline-block" }}
            fullWidth
            InputProps={{
              startAdornment: <AttachFileIcon />,
            }}
          />
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <Button
            disabled={label || !clubData || clubData.length === 0}
            variant="contained"
            onClick={() => handleChange(2)}
            style={{ width: "200px", marginRight: 5, display: "inline-block" }}
          >
            Ballot Labels
          </Button>
          <Button
            disabled={reg || !clubData || clubData.length === 0}
            variant="contained"
            onClick={() => handleChange(1)}
            style={{ width: "200px", marginLeft: 5, display: "inline-block" }}
          >
            Registration Tools
          </Button>
        </div>
        <br />
        {reg && (
          <>
            <div style={{ textAlign: "center" }}>
              <TextField
                style={{ marginRight: "4px" }}
                id="outlined-basic"
                label="Debate Judge Pool"
                variant="outlined"
                value={debatePool}
                onChange={(e) => setDebatePool(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="IE Judge Pool"
                variant="outlined"
                value={iePool}
                onChange={(e) => setIEPool(e.target.value)}
              />
            </div>
            <br />

            <div style={{ textAlign: "center" }}>
              <Button
                style={{ marginRight: "4px" }}
                disabled={!clubData || clubData.length === 0 || !selectedFile}
                variant="contained"
                onClick={() => handleSubmission(selectedFile, seperate)}
              >
                Process Again
              </Button>
              <Button
                disabled={!clubData || clubData.length === 0}
                variant="contained"
                onClick={genCsv}
              >
                Download Spreadsheet
              </Button>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={!clubData || clubData.length === 0}
                variant="contained"
                onClick={genEntries}
                style={{ marginTop: "15px" }}
              >
                Download Entries
              </Button>
            </div>
          </>
        )}
        <br />
        {label && labelData && (
          <div style={{ width: 300 }}>
            <FormControl fullWidth>
              <InputLabel id="labelEvent">Event</InputLabel>
              <Select
                labelId="labelEvent"
                id="labelSelect"
                value={labelEvent}
                label="Event"
                onChange={(e) => handleNewEvent(e.target.value)}
              >
                {labelData.map((labelItem: any) => {
                  return (
                    <MenuItem key={labelItem.id} value={labelItem.id}>
                      {labelItem.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
        <br />
        {label && eventIndex !== null && labelData ? (
          <div style={{ width: 300 }}>
            <FormControl fullWidth>
              <InputLabel id="labelRound">Round</InputLabel>
              <Select
                labelId="labelRound"
                id="labelSelectRound"
                value={labelRound}
                label="Round"
                onChange={(e) => handleNewRound(e.target.value)}
              >
                {labelData[eventIndex].rounds.map((labelItem: any) => {
                  if (labelItem.hasOwnProperty("sections")) {
                    return (
                      <MenuItem key={labelItem.id} value={labelItem.id}>
                        {labelItem.label
                          ? labelItem.label
                          : labelItem.name
                          ? `Round ${labelItem.name}`
                          : ""}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </div>
        ) : null}
        {label && eventIndex != null && labelRound ? (
          <div>
            <div>
              <TextField
                style={{ marginTop: "15px" }}
                id="outlined-basic"
                label="Starting Label Index"
                variant="outlined"
                value={startingLabel}
                onChange={(e) => setStartingLabel(e.target.value)}
              />
              {final &&
                eventIndex &&
                (labelData[eventIndex]?.abbr === "VTD" ||
                  labelData[eventIndex]?.abbr === "NTD") && (
                  <FormControlLabel
                    style={{ marginTop: "15px", marginLeft: "15px" }}
                    control={
                      <Checkbox
                        value={flip}
                        onChange={(e) => setFlip(e.target.checked)}
                      />
                    }
                    label="Flip?"
                  />
                )}
              {eventIndex &&
              (labelData[eventIndex]?.abbr === "VTD" ||
                labelData[eventIndex]?.abbr === "NTD") ? null : (
                <TextField
                  style={{ marginTop: "15px", marginLeft: "15px" }}
                  id="outlined-basic"
                  label="Number of Judges"
                  variant="outlined"
                  value={numJudges}
                  onChange={(e) => setNumJudges(e.target.value)}
                />
              )}
            </div>
            <div>
              <Button
                style={{ marginTop: "15px" }}
                variant="contained"
                onClick={ballotLabels}
              >
                Generate Ballot Labels
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      {reg && clubData && clubData.length > 0 && !label && (
        <div style={{ margin: "20px" }}>
          <table>
            <tbody>
              <tr>
                <th>{"Club"}</th>
                <th>{"Family"}</th>
                <th>{"Fee"}</th>
                <th>{"IE Judges"}</th>
                <th>{"Debate Judges"}</th>
                <th>{"Judge Names"}</th>
              </tr>
              {clubData.map((club: any, clubIndex: number) => {
                return club.families.map((family: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          clubIndex % 2 === 0 ? "#d3d3d3" : "#FFFFFF",
                      }}
                    >
                      <td>
                        <span>{club.name}</span>
                      </td>
                      <td>{family.name}</td>
                      <td>{`$${family.fee}`}</td>
                      <td
                        style={{
                          backgroundColor:
                            family.regIE < family.famIE ? "#fbf719" : "#d3d3d3",
                        }}
                      >
                        {`${family.regIE} of ${family.famIE}`}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            family.regDebate < family.famDebate
                              ? "#fbf719"
                              : "#d3d3d3",
                        }}
                      >
                        {`${family.regDebate} of ${family.famDebate}`}
                      </td>
                      <td>{family.judges}</td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

/*
<div>
  <FormControl>
  <FormLabel id="demo-controlled-radio-buttons-group">Speech and debate judges seperate people?</FormLabel>
  <RadioGroup
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="controlled-radio-buttons-group"
    value={seperate}
    onChange={handleChange}
  >
    <FormControlLabel value={true} control={<Radio />} label="Yes" />
    <FormControlLabel value={false} control={<Radio />} label="No" />
  </RadioGroup>
  </FormControl>
</div>
*/
