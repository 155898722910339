import React from "react";
import "./App.css";
import FileUpload from "./components/FileUpload";
import { generate } from "@pdfme/generator";
import { Template, BLANK_PDF } from "@pdfme/common";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Labels from "./components/Labels";
import Header from "./components/header";

const fontSize = 12;
const labelWidth = 99;
const labelHeight = 38;
const sheetHeight = 297;
const sheetWidth = 210;
const sheetCols = 2;
const sheetRows = 7;

let labelTemplate = require("./labelTemplate.json");
const template: Template = {
  ...labelTemplate,
  basePdf: BLANK_PDF,
};
/*const template: Template = {
  basePdf: BLANK_PDF,
  schemas: [
    {
      0: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 0, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      1: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 1, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      2: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 2, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      3: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 3, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      4: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 4, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      5: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 5, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      6: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 6, y: (sheetWidth / sheetCols) * 0 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      7: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 0, y: (sheetWidth / sheetCols) * 1 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      8: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 0, y: (sheetWidth / sheetCols) * 2 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      9: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 1, y: (sheetWidth / sheetCols) * 1 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      10:
      {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 2, y: (sheetWidth / sheetCols) * 2},
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      11:
      {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 3, y: (sheetWidth / sheetCols) * 3 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
      13: {
        type: "text",
        position: { x: (sheetHeight / sheetRows) * 6, y: (sheetWidth / sheetCols) * 1 },
        width: labelWidth,
        height: labelHeight,
        alignment: "center",
        fontSize: fontSize,
      },
    }]
}*/

//const inputs = [{ "{1}Name": "0", "{5}Name": "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6",
//  7: "7", 8: "8", 9: "9", "{10}Name": "10", 11: "11", 13: "zzzzz" }];
let myPdf: any;

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FileUpload />} />
          <Route path="/labels" element={<Labels />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
