export default async function entryCsv(clubData: any, entries: any) {
  let rows: any[] = [];
  let headers: any[] = entries.map((entry: any) => entry.abbr);

  headers = ["First", "Last", "Club"];

  entries
    .filter((entry: any) => entry.abbr.startsWith("JR"))
    .forEach((entry: any) => headers.push(entry.abbr));
  entries
    .filter((entry: any) => entry.abbr.startsWith("JV"))
    .forEach((entry: any) => headers.push(entry.abbr));
  entries
    .filter((entry: any) => {
      return !(
        entry.abbr.startsWith("JV") ||
        entry.abbr.startsWith("JR") ||
        entry.name.endsWith("Debate")
      );
    })
    .forEach((entry: any) => headers.push(entry.abbr));

  entries
    .filter((entry: any) => entry.name.endsWith("Debate"))
    .forEach((entry: any) => headers.push(entry.abbr));

  headers.push("Debate Partner");
  headers.push("Duo Partner");
  rows.push(headers);
  clubData.map((club: any) => {
    return club.families.map((family: any) => {
      family.students.map((student: any) => {
        let stu = Array(headers.length).fill("");
        student.events.map((event: any) => {
          const hIndex = headers.findIndex((entry: string) => {
            return entry === event.eventAbbr;
          });
          rows.push();

          stu[hIndex] = "x";
        });
        stu[0] = student.first;
        stu[1] = student.last;
        stu[2] = club.name;
        if (student.partners.debate.length > 0) {
          stu[headers.length - 2] = student.partners.debate;
        }
        if (student.partners.duo.length > 0) {
          stu[headers.length - 1] = student.partners.duo;
        }
        rows.push(stu);
      });
      //rows.push([club.name, family.name, `$${family.fee}`, family.famIE, family.regIE, family.famDebate, family.regDebate])
    });
  });

  let csvData = rows.join("\n");

  const blob = new Blob([csvData], { type: "text/csv" });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement("a");

  // Passing the blob downloading url
  a.setAttribute("href", url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute("download", "CCATournamentEntries.csv");

  // Performing a download with click
  a.click();
}
